import React from "react"
import Thx from "../components/images/thx"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"

const shareUrl = "https://doosjevoltalent.nl"

const shareMsg = "Ik heb zojuist een #doosjevoltalent besteld!"

const Herobox = styled.div`
  color: #3c7683;
  background: radial-gradient(50% 150%, #fdfacb 50%, #e4e1ac 100%);
`

export default function ThanksPage() {
  return (
    <Layout>
      <Seo title="Bedankt voor je bestelling!" />
      <Herobox>
        <div className="container container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="mx-auto my-3 my-sm-5"
                style={{ maxWidth: 494 / 2, maxHeight: 336 / 2 }}
              >
                <Thx />
              </div>
            </div>

            <div className="mx-auto col-md-6 mb-5">
              <div className="p-4 pb-2 mb-3 bg-white rounded-lg shadow">
                <p>
                  Spoedig zul je een betalingsverzoek ontvangen. Zodra deze
                  betaald is, stuur ik de bestelling naar je op.{" "}
                  <strong>Veel plezier met je aankoop!</strong>
                </p>

                <hr />

                <p>
                  Pssst... laat heel jouw netwerk weten wat je zonet voor gaafs
                  hebt besteld!{" "}
                  <span role="img" aria-label="wink">
                    😉
                  </span>
                </p>

                <div className="mx-auto d-flex justify-content-center">
                  <FacebookShareButton
                    className="d-flex-fill p-2"
                    url={shareUrl}
                  >
                    {" "}
                    <FacebookIcon size={32} round={true} />{" "}
                  </FacebookShareButton>
                  <LinkedinShareButton
                    className="d-flex-fill p-2"
                    url={shareUrl}
                  >
                    {" "}
                    <LinkedinIcon size={32} round={true} />{" "}
                  </LinkedinShareButton>
                  <TwitterShareButton
                    className="d-flex-fill p-2"
                    text={shareMsg}
                    url={shareUrl}
                  >
                    {" "}
                    <TwitterIcon size={32} round={true} />{" "}
                  </TwitterShareButton>
                </div>
              </div>

              <div className="mb-3 text-center">
                <Link to="/">Terug naar de site</Link>
              </div>
            </div>
          </div>
        </div>
      </Herobox>
    </Layout>
  )
}
